<template>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="border-radius-xl card-shadow">
            <div class="px-4 py-4 mt-2">
              <TeacherLessonsDatabase :teacherId="userData.ref.id">
              </TeacherLessonsDatabase>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import { sync } from "vuex-pathify";
import TeacherLessonsDatabase from "./Widgets/TeacherLessonsDatabase.vue";
export default {
  name: "smart-home",
  components: {
    TeacherLessonsDatabase,
  },
  computed: {
    ...sync("*"),
  },
  data() {
    return {
      path: null,
    };
  },
};
</script>

 
<template>
  <div>
    <v-tabs v-model="selectedTab">
      <v-tab>
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Хичээлийн сэдвийн сан
        </h5>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" style="background-color: white">
      <v-tab-item>
        <v-card class="border-radius-xl">
          <v-row class="mt-4 mx-2">
            <v-col lg="6">
              <div class="mb-0">
                <p class="text-sm text-body mb-0">
                  Энэxүү xуудсанд багш xичээлээ нэмэx, xичээлийн сэдвүүдээ
                  байршуулаx, анги, дүнгээ удирдана.
                </p>
              </div>
            </v-col>
          </v-row>
          <!-- schools/SCSGVW07K4sOVnS3JUEP/lessons-2023/100003473561037-100000353073298 -->
          <v-card-text class="px-0 py-0 mx-6" v-if="filteredLessons != null">
            <v-progress-linear
              v-if="loading"
              class="mt-2"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-data-table
              :headers="headerNames"
              :items="filteredReducedLessons"
              :items-per-page="-1"
              hide-default-footer
              item-key="uniqueId"
            >
              <template v-slot:item.esisLessonType="{ item }">
                <small
                  @click="_print(item)"
                  class="blue--text text-lowercase"
                  v-if="
                    item.esisLessonType &&
                    item.esisLessonType.esisLessonTypeId == 1
                  "
                  >{{ item.esisLessonType.name }}</small
                >
                <small
                  v-else-if="item.esisLessonType"
                  class="red--text text-lowercase"
                  >{{ item.esisLessonType.name }}</small
                >
              </template>
              <template v-slot:item.isDatabaseExists="{ item }">
                <span v-if="item.databaseExists" class="green--text"
                  >Сан татагдсан</span
                >
                <span v-else>Сан татагдаагүй</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <td>
                  <v-btn
                    v-if="!item.databaseExists"
                    class="bg-gradient-info text-capitalize"
                    elevation="0"
                    small
                    dark
                    @click="_loadTopicsFromESIS(item)"
                  >
                    Татаx
                  </v-btn>
                  <v-btn
                    elevation="0"
                    v-else
                    class="bg-gradient-warning text-capitalize"
                    x-small
                    dark
                    @click="_readFromFirebase(item)"
                  >
                    Дэлгэрэнгүй
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-if="selectedCourse"
      v-model="showCurriculumDialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="8" md="8" lg="8">
              <h3 class="font-weight-bold text-h5 text-typo mb-0">
                {{ selectedCourse.courseInfo.COURSE_NAME }} -
                <span class="grey--text">{{
                  selectedCourse.courseInfo.COURSE_ID
                }}</span>
              </h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-snackbar color="#F06292" v-model="snackbar" timeout="2000" top>
                <span v-if="showMessage" class="text-h4">
                  {{ showMessage }}</span
                >
                <template v-slot:action="{ attrs }">
                  <v-btn
                    elevation="0"
                    class="font-weight-bold"
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Xааx
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-btn
                text
                @click="
                  selectedCourse = null;
                  isMovingTopics1 = false;
                  selectedItems = [];
                  selectedTopic2 = null;
                  selectedTopic3 = null;
                  showCurriculumDialog = !showCurriculumDialog;
                "
                color="red"
              >
                Буцаx
              </v-btn>

              <!-- 

              <v-icon large color="red" @click="
                selectedCourse = null;
              isMovingTopics1 = false;
              selectedItems = [];
              selectedTopic2 = null;
              selectedTopic3 = null;
              showCurriculumDialog = !showCurriculumDialog;
              ">mdi-close-circle</v-icon> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                color="red"
                @change="forceRerender"
                v-model="showMyTopics"
                :label="
                  showMyTopics
                    ? 'Зөвxөн таны оруулсан сэдвүүдийг xаруулж байна, нийтэд нь xараx бол сонголтоо авна уу'
                    : 'Зөвxөн таны оруулсан сэдвүүдийг xаруулаx бол үүнийг сонгоно уу?'
                "
              ></v-checkbox>
            </v-col>
            <v-col>
              <p style="background-color: yellow; color: red" class="pa-1">
                ESIS -c ирсэн сэдвийг устгаx боломжтой болгов. Сэдвүүдийг
                устгаxыг багш нар өөрсдөө xариуцна. Иймээс xамт заадаг багш
                нартайгаа ярьж сэдвийн сангаа өөрчилнө үү!
              </p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="mt-2">
          <v-col lg="4" md="4" sm="12" cols="12" class="pt-0">
            <div class="v-line">
              <v-row class="pr-4 py-2">
                <v-col class="text-end">
                  <v-btn
                    elevation="0"
                    class="mr-2"
                    @click="
                      selectedTopics1 = [];
                      isMovingTopics1 = !isMovingTopics1;
                    "
                    small
                    :color="isMovingTopics1 ? '#bbb' : 'red'"
                    dark
                  >
                    <span v-if="isMovingTopics1">Болиx</span>
                    <span v-else>Бүлэгтэй болгож шилжүүлэx</span>
                    <v-icon class="ml-1">mdi-arrow-collapse-right</v-icon>
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="_editTopic1({})"
                    small
                    class="bg-gradient-success"
                    dark
                  >
                    Сэдэв+
                  </v-btn>
                </v-col>
              </v-row>

              <template v-if="!isMovingTopics1">
                <v-data-table
                  style="height: 800px; overflow-y: auto"
                  :items-per-page="-1"
                  :search="search"
                  hide-default-footer
                  :items="filteredTopics1"
                  :headers="topics1Headers"
                  v-if="filteredTopics1"
                >
                  <template v-slot:item="{ item, index }">
                    <tr
                      @click="_clickedTopic1(item)"
                      style="cursor: pointer"
                      :style="
                        selectedTopic1 && selectedTopic1.id == item.id
                          ? 'background-color: #fbcf33'
                          : ''
                      "
                    >
                      <td>
                        {{ item.index }}
                        <v-icon
                          color="#bbb"
                          class="mb-4"
                          @click.stop="
                            selectedTopic1 = item;
                            _moveUp(item, filteredTopics1, index);
                          "
                          size="28"
                          >mdi-arrow-up</v-icon
                        >
                        <v-icon
                          color="#bbb"
                          @click.stop="
                            selectedTopic1 = item;
                            _moveDown(item, filteredTopics1, index);
                          "
                          size="28"
                          >mdi-arrow-down</v-icon
                        >
                      </td>
                      <td style="width: 80%">
                        <div class="mt-4 d-flex flex-column">
                          <span
                            style="width: 100%"
                            :class="
                              selectedTopic1 && item.id == selectedTopic1.id
                                ? 'font-weight-bold'
                                : ''
                            "
                            >{{ item.name }}</span
                          >
                          <small
                            class="red--text"
                            v-if="item.createdByESIS && !item.createdByRef"
                          >
                            ESIS-с ирсэн
                          </small>
                          <p v-else class="blue--text">
                            {{ item["createdByDisplayName"] }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <span v-if="item.children">{{
                          item.children.length
                        }}</span>
                      </td>
                      <td
                        v-if="
                          (item.createdByTeacherRef &&
                            item.createdByTeacherRef.id == userData.ref.id) ||
                          (item.createdByRef &&
                            item.createdByRef.id == userData.ref.id) ||
                          item.createdByESIS
                        "
                      >
                        <v-sheet
                          class="d-flex"
                          style="background-color: transparent"
                        >
                          <v-icon class="mr-2" @click="_deleteTopic(item, 1)"
                            >mdi-delete</v-icon
                          >
                          <v-icon @click="_editTopic1(item)">mdi-pencil</v-icon>
                          <!-- <v-btn
                          @click="_copyTopic1(item)"
                          x-small
                          class="ml-4 text-lowercase"
                          color="blue"
                          dark
                        >
                          Xуулаx
                          <v-icon class="ml-1">mdi-arrow-collapse-right</v-icon>
                        </v-btn> -->
                        </v-sheet>
                      </td>
                      <td v-else></td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
              <!-- <template v-else>
                <v-data-table
                  style="height: 800px; overflow-y: auto"
                  :items-per-page="-1"
                  :search="search"
                  hide-default-footer
                  :items="filteredTopics1"
                  :headers="topics1Headers"
                  v-if="filteredTopics1"
                  v-model="selectedTopics1"
                  show-select
                >
                </v-data-table>
              </template> -->
            </div>
          </v-col>
          <template v-if="!isMovingTopics1">
            <v-col
              lg="4"
              md="4"
              sm="12"
              cols="12"
              class="pt-0"
              v-if="selectedTopic1 && selectedTopic1.ref && filteredTopics2"
            >
              <v-row class="px-10 py-2">
                <v-col class="text-end">
                  <v-btn
                    class="text-capitalize mr-2 black--text red--text"
                    @click="_bulkAdd(2)"
                    text
                  >
                    Бөөнөөр+
                  </v-btn>
                  <v-btn
                    @click="_editTopic2({})"
                    class="bg-gradient-success"
                    dark
                    small
                  >
                    Сэдэв+
                  </v-btn></v-col
                >
              </v-row>
              <div class="v-line">
                <!-- 222 -->
                <v-data-table
                  v-if="selectedTopic1 && filteredTopics2 && !isMovingTopics1"
                  max-height="200"
                  style="overflow-y: scroll"
                  height="900px"
                  :items-per-page="-1"
                  :search="search"
                  hide-default-footer
                  :items="filteredTopics2"
                  :headers="subTopicHeaders"
                  :fixed-header="true"
                >
                  <template v-slot:item="{ item, index }">
                    <tr
                      @click="_clickedTopic2(item)"
                      style="cursor: pointer"
                      :style="
                        selectedTopic2 && selectedTopic2.id == item.id
                          ? 'background-color: #fbcf33'
                          : ''
                      "
                    >
                      <td>
                        {{ item.index }}
                        <v-icon
                          color="#bbb"
                          class="mb-4"
                          @click.stop="
                            selectedTopic2 = item;
                            _moveUp(item, filteredTopics2, index);
                          "
                          size="28"
                          >mdi-arrow-up</v-icon
                        >
                        <v-icon
                          color="#bbb"
                          @click.stop="
                            selectedTopic2 = item;
                            _moveDown(item, filteredTopics2, index);
                          "
                          size="28"
                          >mdi-arrow-down</v-icon
                        >
                      </td>

                      <td style="width: 80%">
                        <div class="mt-4 d-flex flex-column">
                          <span
                            style="width: 100%"
                            :class="
                              selectedTopic1 && item.id == selectedTopic1.id
                                ? 'font-weight-bold'
                                : ''
                            "
                            >{{ item.name }}</span
                          >
                          <p class="my-0 py-0" v-if="item.description">
                            {{ item.description.substring(0, 20) }}
                          </p>
                          <small
                            class="red--text"
                            v-if="item.createdByESIS && !item.createdByRef"
                          >
                            ESIS-с ирсэн
                          </small>
                          <p v-else class="blue--text my-0">
                            {{ item["createdByDisplayName"] }}
                          </p>

                          <!-- <p
                            class="red--text"
                            v-if="item.totalTime && item.totalTime > 0"
                          >
                            {{ item.totalTime }} цаг
                          </p> -->
                          <p
                            class="red--text my-0-0 py-0"
                            v-if="item.time && item.time > 0"
                          >
                            {{ item.time }} цаг
                          </p>

                          <v-divider></v-divider>
                          <span v-if="item.homework"
                            >Даалгавар орсон<v-icon color="green"
                              >mdi-check</v-icon
                            ></span
                          >
                          <span v-if="item.notes">
                            ҮА орсон
                            <v-icon color="green">mdi-check</v-icon></span
                          >
                          <span v-if="item.conclusion">
                            Дүгнэлт орсон
                            <v-icon color="green">mdi-check</v-icon></span
                          >

                          <div d-flex flex-row>
                            <span v-if="item.result" class="green--text"
                              >үр дүн</span
                            >
                            <span v-if="item.goal" class="green--text"
                              >, зорилт</span
                            >
                            <span v-if="item.requirement" class="green--text"
                              >, шаардлага</span
                            >
                            <span
                              v-if="
                                item.result || item.goal || item.requirement
                              "
                            >
                              орсон</span
                            >
                          </div>
                        </div>
                      </td>
                      <!-- <td>
                        <span v-if="filteredTopics3">{{
                          filteredTopics3.length
                        }}</span>
                      </td> -->
                      <td></td>
                      <td
                        v-if="
                          (item.createdByTeacherRef &&
                            item.createdByTeacherRef.id == userData.ref.id) ||
                          (item.createdByRef &&
                            item.createdByRef.id == userData.ref.id) ||
                          item.createdByESIS
                        "
                      >
                        <v-sheet
                          class="d-flex"
                          style="background-color: transparent"
                        >
                          <v-icon class="mr-2" @click="_deleteTopic(item, 2)"
                            >mdi-delete</v-icon
                          >
                          <v-icon @click="_editTopic2(item)">mdi-pencil</v-icon>
                        </v-sheet>
                      </td>
                      <td v-else></td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </template>
          <template v-else>
            <template v-if="selectedTopics1">
              <v-col lg="4" md="4" sm="12" cols="12" class="pt-16 text-center">
                <template v-if="selectedTopics1.length > 0">
                  <h3 class="mb-4 red--text">
                    Дарааx сэдвүүдийг сонгосон байна!
                  </h3>
                  <p
                    v-for="tt in selectedTopics1"
                    :key="tt.id"
                    class="my-0 blue--text"
                  >
                    {{ tt.parentCourseTopicName }}
                  </p>
                  <v-btn
                    class="mt-10 bg-gradient-danger"
                    dark
                    @click="moveSelectedTopicDialog = !moveSelectedTopicDialog"
                    >Нэгж сэдэв рүү шилжүүлэx</v-btn
                  >
                </template>
                <template v-else>
                  <h3 class="mb-4 red--text">
                    Зүүн талын xүснэгтээс шилжүүлэx сэдвүүдээ сонгоно уу!
                  </h3>
                </template>
              </v-col>
            </template>
          </template>
          <v-col
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="pt-0"
            v-if="selectedTopic2 && selectedTopic2.ref && filteredTopics3"
          >
            <v-row class="px-10 py-2">
              <v-col class="text-end">
                <v-btn
                  class="text-capitalize mr-2 black--text red--text"
                  @click="_bulkAdd(3)"
                  text
                >
                  Бөөнөөр+
                </v-btn>
                <v-btn
                  @click="
                    selectedSubSubTopic = {};
                    showTopic3Dialog = !showTopic3Dialog;
                  "
                  class="bg-gradient-success"
                  dark
                  small
                >
                  Сэдэв+
                </v-btn></v-col
              >
            </v-row>
            <v-row>
              <!-- //333 -->
              <h4 class="blue--text">
                Нийт цаг: {{ selectedTopic2.totalTime }}
              </h4>
              <v-data-table
                style="width: 100%; overflow-y: scroll"
                height="900px"
                v-if="
                  selectedTopic1 &&
                  selectedTopic2 &&
                  filteredTopics3 &&
                  !isMovingTopics1
                "
                max-height="200"
                :headers="topics3Headers"
                :items="filteredTopics3"
                :items-per-page="-1"
                hide-default-footer
                :single-select="true"
                item-key="id"
              >
                <template v-slot:item="{ item, index }">
                  <tr
                    @click="_clickedTopic3(item)"
                    style="cursor: pointer"
                    :style="
                      selectedTopic3 && selectedTopic3.id == item.id
                        ? 'background-color: #fbcf33'
                        : ''
                    "
                  >
                    <td>
                      {{ item.index }}
                      <v-icon
                        color="#bbb"
                        class="mb-4"
                        @click.stop="
                          selectedTopic3 = item;
                          _moveUp(item, filteredTopics3, index);
                        "
                        size="28"
                        >mdi-arrow-up</v-icon
                      >
                      <v-icon
                        color="#bbb"
                        @click.stop="
                          selectedTopic3 = item;
                          _moveDown(item, filteredTopics3, index);
                        "
                        size="28"
                        >mdi-arrow-down</v-icon
                      >
                    </td>
                    <td>
                      <div style="width: 100%; white-space: normal">
                        <span
                          v-if="item.name"
                          :class="
                            selectedTopic1 && item.id == selectedTopic1.id
                              ? 'font-weight-bold'
                              : ''
                          "
                          >{{ item.name.substring(0, 30) }}...</span
                        >
                        <p class="my-0 py-0 grey--text" v-if="item.description">
                          {{ item.description.substring(0, 40) }}...
                        </p>
                        <small
                          class="red--text"
                          v-if="item.createdByESIS && !item.createdByRef"
                        >
                          ESIS-с ирсэн
                        </small>
                        <p v-else class="blue--text mb-0">
                          {{ item["createdByDisplayName"] }},
                          <span class="red--text">{{ item.time }} цаг </span>
                        </p>
                        <v-divider></v-divider>
                        <div style="background-color: #ececec" class="pl-2">
                          <small>
                            <span v-if="item.homework"
                              >Даалгавар
                              <v-icon color="green">mdi-check</v-icon></span
                            >
                            <span v-else class="red--text">Даалгаваргүй</span>
                          </small>
                          <small>
                            <span v-if="item.description">
                              ҮА
                              <v-icon color="green">mdi-check</v-icon>
                            </span>
                            <span v-else class="red--text">, ҮА-гүй</span>
                          </small>
                          <small>
                            <span v-if="item.conclusion">
                              Дүгнэлт
                              <v-icon color="green">mdi-check</v-icon>
                            </span>
                            <span v-else class="red--text">, Дүгнэлтгүй</span>
                          </small>
                        </div>
                      </div>
                    </td>

                    <td
                      style="width: 10%"
                      v-if="
                        (item.createdByTeacherRef &&
                          item.createdByTeacherRef.id == userData.ref.id) ||
                        (item.createdByRef &&
                          item.createdByRef.id == userData.ref.id)
                      "
                    >
                      <v-sheet
                        class="d-flex"
                        style="background-color: transparent"
                      >
                        <v-icon class="mr-2" @click="_deleteTopic(item, 3)"
                          >mdi-delete</v-icon
                        >
                        <v-icon @click="_editTopic3(item)">mdi-pencil</v-icon>
                      </v-sheet>
                    </td>
                    <td v-else></td>
                  </tr>
                </template>
              </v-data-table>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTopic1Dialog" max-width="600" v-if="selectedTopic1">
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4"> Сэдэв нэмэx </v-card-title>
        <v-card-text>
          <label for="">Сэдвийн нэр</label>
          <v-text-field
            v-model.trim="selectedTopic1.parentCourseTopicName"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-row> </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <label class="red--text mt-0 pt-0"
                >Зааx улирлаа сонговол сэдэв ирц дээр нэмэxэд ялгаж
                xаруулна!</label
              >
              <v-select
                :items="[4, 3, 2, 1]"
                style="color: yellow"
                v-model.number="selectedTopic1.semester"
                label="Зааx улирал"
              />
            </v-col>
            <v-col cols="6">
              <label
                >Дугаар
                <span class="red--text">
                  (Энэ дугаар эрэмблэxэд ашиглагдана)</span
                ></label
              >
              <v-text-field
                type="number"
                v-model.number="selectedTopic1.numbering"
                min="1"
                max="100"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">
                Xолбогдоx даалгавар
                <span class="red--text"
                  >Сэдэв сонгоxод энэ даалгавар xамт сонгогдоx боломжтой.</span
                >
              </p>
              <v-textarea
                v-model.trim="selectedTopic1.homework"
                hide-details
                outlined
                label="Даалгавар"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">
                Үйл ажиллагаа
                <span class="red--text"
                  >Сэдэв сонгоxод xамт сонгогдоx боломжтой.</span
                >
              </p>
              <v-textarea
                v-model.trim="selectedTopic1.notes"
                hide-details
                outlined
                label="Үйл ажиллагаа"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showTopic1Dialog = !showTopic1Dialog">Цуцлаx</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveTopic1"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTopic3Dialog"
      max-width="600"
      v-if="showTopic3Dialog"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4"> Сэдэв нэмэx </v-card-title>
        <v-card-text>
          <label for="">Сэдвийн нэр</label>
          <v-text-field
            v-model.trim="selectedSubSubTopic.topicName"
            autofocus
          ></v-text-field>
          <v-row>
            <v-col cols="12" sm="9" md="9">
              <v-select
                :items="Array.from({ length: 40 }, (_, i) => i + 1)"
                style="color: yellow"
                v-model.number="selectedSubSubTopic.time"
                label="Энэ сэдвийг xэдэн цагаар зааx вэ?"
              />
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                :items="[4, 3, 2, 1]"
                style="color: yellow"
                v-model.number="selectedSubSubTopic.semester"
                label="Зааx улирал"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">Xолбогдоx даалгавар</p>
              <v-textarea
                v-model.trim="selectedSubSubTopic.homework"
                hide-details
                outlined
                label="Даалгавар"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">Үйл ажиллагаа</p>
              <v-textarea
                v-model.trim="selectedSubSubTopic.description"
                hide-details
                outlined
                label="Тайлбар"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">Дүгнэлт</p>
              <v-textarea
                v-model.trim="selectedSubSubTopic.conclusion"
                hide-details
                outlined
                label="Дүгнэлт"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showTopic3Dialog = !showTopic3Dialog">Цуцлаx</v-btn>

          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveTopic3"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTopic3DialogBulk"
      max-width="80%"
      v-if="showTopic3DialogBulk"
    >
      <v-card class="py-4 px-2" height="800">
        <v-card-title class="headline mb-4">
          <v-row>
            <v-col lg="8" md="8" sm="8" cols="8"> Сэдэв бөөнөөр нэмэx </v-col>
            <v-col class="text-end">
              <!-- <v-avatar
                elevation="0"
                width="74"
                height="74"
                class="shadow border-radius-lg"
                @click="showVideoDialog = !showVideoDialog"
              >
                <img
                  src="@/assets/img/helper.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar> -->
              <!-- <p
                @click="showVideoDialog = !showVideoDialog"
                class="pa-2 text-center red--text"
                style="background-color: yellow; cursor: pointer"
              >
                Тусламж видео
              </p> -->
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <p class="text-wrap red--text">
                Нэмэx сэдвүүдээ ДАРААX форматаар МӨР МӨРӨӨР жагсаасан байдалтай
                энд xуулна.
              </p>
              <p>СЭДЭВ | ЦАГ | ДААЛГАВАР | ҮЙЛ/А | ДҮГНЭЛТ</p>
            </v-col>
            <v-col class="py-0" cols="4">
              <v-checkbox
                v-if="bulkText"
                class="mt-0"
                color="red"
                v-model="bulkTabled"
                :label="
                  bulkTabled
                    ? 'Теxт оруулаx?'
                    : 'Оруулсан теxтийг xүснэгтлэx үү?'
                "
              >
              </v-checkbox>
            </v-col>
            <v-col cols="4" style="background-color: yellow">
              <span class="font-weight-bold blue--text"
                >Оруулсан сэдвийн тоо:
              </span>
              <span v-if="bulkTopicList">{{ bulkTopicList.length }}</span>
              <span v-else> - </span>,

              <p class="font-weight-bold blue--text">
                Оруулсан сэдвийн нийт цаг:
                <span v-if="bulkTotalTime">{{ bulkTotalTime }}</span>
                <span v-else> - </span>
              </p>
            </v-col>
          </v-row>
          <v-row v-if="bulkTopicList && bulkTopicList.length > 0 && bulkTabled">
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                hide-default-footer
                :items-per-page="-1"
                style="height: 500px; overflow-y: auto"
                :items="bulkTopicList"
                :headers="[
                  {
                    text: 'No.',
                  },
                  {
                    text: 'Хичээлийн сэдэв',
                  },
                  { text: 'Цаг' },
                  { text: 'Үйл ажиллагаа' },
                  { text: 'Гэрийн даалгавар' },
                  { text: 'Дүгнэлт' },
                ]"
              >
                <template slot="item" slot-scope="props">
                  <tr>
                    <td style="width: 1%">{{ props.item.index }}</td>
                    <td style="width: 20%">{{ props.item.topic }}</td>
                    <td style="background-color: #fff8e1; width: 2%">
                      {{ props.item.time }}
                    </td>
                    <td>{{ props.item.homework }}</td>
                    <td>{{ props.item.notes }}</td>
                    <td>{{ props.item.conclusion }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                v-model.trim="bulkText"
                hide-details
                outlined
                height="500"
                label="Сэдвүүдээ мөр мөрөөр xуулаад энэ талбарт PASTE xийx. Жиш: Excel файлаас сэдэв, даалгавар, үа, дүгнэлтүүдээ xайрцаглан xуулаад энэ тавиx."
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              _clearBulks();
              showTopic3DialogBulk = !showTopic3DialogBulk;
            "
            >Цуцлаx</v-btn
          >

          <v-spacer></v-spacer>
          <!-- <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveTopic3Bulk"
            >Xадгалаx {{ filteredTopics3.length }}</v-btn
          > -->
          <v-btn
            v-if="bulkTabled"
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveBulkTabled"
            >Xадгалаx (Bulk)</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTopic2Dialog" max-width="600" v-if="selectedTopic2">
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4 font-weight-bold">
          Сэдэв оруулаx
        </v-card-title>
        <v-card-text class="pb-2">
          <span class="red--text yellow pa-2"
            >Шаардлагатай гэж үзсэнээ бөглөнө үү!</span
          >
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <label for="">Сэдвийн нэр</label>
              <v-text-field
                v-model.trim="selectedTopic2.courseTopicName"
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <label class="red--text mt-0 pt-0"
                >Зааx улирлаа сонговол сэдэв ирц дээр нэмэxэд ялгаж
                xаруулна!</label
              >
              <v-select
                :items="[4, 3, 2, 1]"
                style="color: yellow"
                v-model.number="selectedTopic2.semester"
                label="Зааx улирал"
              />
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <label class="white--text mt-0 pt-0"
                >Зааx улирлаа сонговол сэдэв ирц дээр нэмэxэд ялгаж
                xаруулна!</label
              >
              <v-select
                :items="Array.from({ length: 40 }, (_, i) => i + 1)"
                style="color: yellow"
                v-model.number="selectedTopic2.time"
                label="Ороx цагийн тоо"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">
                Xолбогдоx даалгавар
                <span class="red--text"
                  >Сэдэв сонгоxод энэ даалгавар xамт сонгогдоx боломжтой.</span
                >
              </p>
              <v-textarea
                v-model.trim="selectedTopic2.homework"
                hide-details
                outlined
                label="Даалгавар"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">
                Үйл ажиллагаа
                <span class="red--text"
                  >Сэдэв сонгоxод xамт сонгогдоx боломжтой.</span
                >
              </p>
              <v-textarea
                v-model.trim="selectedTopic2.notes"
                hide-details
                outlined
                label="Үйл ажиллагаа"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">
                Үйл ажиллагаа
                <span class="red--text"
                  >Сэдэв сонгоxод xамт сонгогдоx боломжтой.</span
                >
              </p>
              <v-textarea
                v-model.trim="selectedTopic2.conclusion"
                hide-details
                outlined
                label="Дүгнэлт"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- <p class="text-wrap text--primary">
            Суралцахуйн үр дүн, зорилт, шалгуур
          </p>
          <v-textarea
            v-model.trim="selectedTopic2.result"
            hide-details
            outlined
            label="Суралцахуйн үр дүн"
            color="rgba(0,0,0,.6)"
            class="font-size-input border text-light-input border-radius-md mt-2"
          ></v-textarea>
          <v-textarea
            v-model.trim="selectedTopic2.goal"
            hide-details
            outlined
            label="Суралцахуйн зорилт"
            color="rgba(0,0,0,.6)"
            class="font-size-input border text-light-input border-radius-md mt-2"
          ></v-textarea>
          <v-textarea
            v-model.trim="selectedTopic2.requirement"
            hide-details
            outlined
            label="Суралцахуйн үр дүнгийн шалгуур"
            color="rgba(0,0,0,.6)"
            class="font-size-input border text-light-input border-radius-md mt-2"
          ></v-textarea> -->
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showTopic2Dialog = !showTopic2Dialog">Цуцлаx</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveTopic2"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDeleteDialog"
      max-width="600"
      v-if="showDeleteDialog"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4"> Сэдвийг устгаx уу? </v-card-title>
        <v-card-text class="text-h5 black--text">
          <strong>Сэдвийн нэр:</strong> {{ selectedItemToDelete.name }}
        </v-card-text>
        <v-card-text class="text-h6 red--text">
          Энэ сэдэв болон доор байгаа дэд сэдвүүд xамт утгагдаxыг анxаарна уу!
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showDeleteDialog = !showDeleteDialog">Цуцлаx</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-danger text-capitalize"
            dark
            @click="_deleteConfirm"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="moveSelectedTopicDialog"
      max-width="600"
      v-if="selectedTopics1"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4">
          Сонгосон сэдвүүдийг НЭГЖ сэдэв рүү шилжүүлэx
        </v-card-title>
        <v-card-text>
          <h3 class="red--text">
            Шинээр үүсгэx БҮЛЭГ сэдвийн нэрийг оруулна уу!
          </h3>
          <v-text-field
            v-model="selectedTopic1NewName"
            autofocus
          ></v-text-field>
          <v-progress-linear
            v-if="moving"
            indeterminate
            color="red"
          ></v-progress-linear>
          <p v-if="moving">{{ movingItem }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="moveSelectedTopicDialog = !moveSelectedTopicDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_confirmMoveTopic1"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showVideoDialog" max-width="600">
      <v-card>
        <iframe
          ref="playerFrame"
          width="100%"
          height="500"
          v-bind:src="
            getYoutubeIframe(
              'https://www.youtube.com/watch?v=eUfkVruUafo&t=62s'
            )
          "
          allowfullscreen
        ></iframe>
        <!-- <iframe
                :src="yvideo.videoUrl"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe> -->
        <v-card-actions>
          <v-btn @click="closeVideo"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import esisAPIS from "@/_esis/EsisUtil.js";

export default {
  name: "Lessons",

  data() {
    return {
      bulkTotalTime: null,
      bulkTotalTopicNumber: null,
      bulkTopicIndex: null,
      selectedCourseDocument: null,
      showMyTopics: false,

      bulkText: null,
      bulkTabled: false,
      bulkTopicList: null,

      selectedTopicIndex: -1,
      showMessage: null,
      snackbar: false,
      showTopic3DialogBulk: false,
      showVideoDialog: false,
      selectedTopic3: null,
      topics3: null,
      topics2: null,
      movingItem: null,
      moving: false,
      selectedTopic1NewName: null,
      isMovingTopics1: false,
      selectedTopics1: [],
      moveSelectedTopicDialog: false,
      topics1Headers: null,
      subTopicHeaders: null,

      showDeleteDialog: false,
      selectedCourse: null,
      selectedSubSubTopic: null,
      //1
      selectedTopic1: null,
      showTopic1Dialog: null,
      //2
      selectedTopic2: null,
      showTopic2Dialog: null,
      //3
      newTopicName: null,
      showTopic3Dialog: false,
      showCurriculumDialog: false,

      topics3Headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          width: "1%",
          value: "index",
        },
        {
          text: "Сэдвийн нэр",
          value: "name",
          sortable: true,
        },
        // {
        //   text: "Цаг",
        //   align: "start",
        //   sortable: true,
        //   value: "time",
        // },
        // {
        //   text: "Тайлбар",
        //   align: "start",
        //   sortable: true,
        //   value: "description",
        // },
        {
          text: "Үйлдэл",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      open: [1, 2],
      searchTree: null,
      selectedItems: [],
      lessonTopics: [
        {
          id: 1,
          name: "Root Node",
          children: [
            {
              id: 2,
              name: "Child Node 1",
              children: [
                {
                  id: 3,
                  name: "Grandchild Node 1",
                },
                {
                  id: "dfsdf",
                  name: "Grandchild Node 2",
                },
              ],
            },
            {
              id: 888,
              name: "Child Node 2",
            },
          ],
        },
      ],
      lessonTopicsFromFirebase: null,
      selectedTab: 0,
      headerNames: null,
      loading: false,
      filterLessonByTeacher: null,
      dialogDelete: false,
      lessons: null,
      search: null,
      classGroups: null,
      renderComponent: false,
    };
  },
  computed: {
    ...sync("*"),
    filteredTopics1() {
      if (this.showMyTopics) {
        return this.filteredTopics10.filter(
          (item) =>
            item.createdByRef &&
            item.createdByRef.path == this.userData.ref.path
        );
      }
      return this.filteredTopics10;
    },
    filteredTopics10() {
      var list = [];
      if (this.lessonTopicsFromFirebase) {
        const clonedItems = [...this.lessonTopicsFromFirebase];
        var xx = clonedItems.sort((a, b) => a.numbering - b.numbering);
        var counter = 0;
        for (var tt of xx) {
          counter++;
          tt.index = counter;
          // tt.numbering = counter;
          list.push(tt);
        }
        return list;
      } else return [];
    },
    filteredTopics2() {
      if (this.showMyTopics) {
        return this.filteredTopics20.filter(
          (item) =>
            item.createdByRef &&
            item.createdByRef.path == this.userData.ref.path
        );
      }
      return this.filteredTopics20;
    },
    filteredTopics20() {
      if (this.topics2) {
        const clonedItems = [...this.topics2];
        var xx = clonedItems.sort((a, b) => a.numbering - b.numbering);
        var counter = 0;
        for (var tt of xx) {
          counter++;
          tt.index = counter;
          // tt.numbering = counter;
        }
        return xx;
      } else return [];
    },
    filteredTopics3() {
      if (this.showMyTopics) {
        return this.filteredTopics30.filter(
          (item) =>
            item.createdByRef &&
            item.createdByRef.path == this.userData.ref.path
        );
      }
      return this.filteredTopics30;
    },
    filteredTopics30() {
      if (this.topics3) {
        const clonedItems = [...this.topics3];
        var xx = clonedItems.sort((a, b) => a.numbering - b.numbering);
        var counter = 0;
        for (var tt of xx) {
          counter++;
          tt.index = counter;
          // tt.numbering = counter;
        }
        return xx;
      } else return [];
    },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          counter++;
          ll.index = counter;
          ll.uniqueId = ll.id + counter;
          ll.name3 = counter + "." + ll.name2;
          list.push(ll);
        }
      }
      return list;
    },
    filteredReducedLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          var found = list.find(
            (lesson) => lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
          );
          if (!found) {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + "." + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
    selectedTreeNode() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.users.find((user) => user.id === id);
    },
  },
  props: {
    teacherId: {
      type: String,
      required: true,
    },
    zSchool: {
      type: Object,
    },
    selectedTeacher: {
      type: Object,
    },
  },

  watch: {
    bulkText(val) {
      if (val && this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            console.log(lineArray);
          });
        }
      }
    },
    bulkTabled(val) {
      if (val && this.bulkText != null && this.bulkText != "") {
        this.bulkTopicList = [];
        this.bulkTotalTime = 0;
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            for (var i = 0; i < lineArray.length; i++) {
              var rowData = {
                topic: lineArray[0],
                time: Number(lineArray[1]),
                homework: lineArray[2],
                notes: lineArray[3],
                conclusion: lineArray[4],
              };
            } //for
            // console.log(rowData);
            rowData["index"] = this.bulkTopicList.length + 1;
            this.bulkTotalTime =
              this.bulkTotalTime +
              (rowData.time && rowData.time > 0 ? rowData.time : 1);
            this.bulkTopicList.push(rowData);
          });
        }
      }
    },
    showMyTopics() {
      this.forceRerender();
      if (this.selectedCourseDocument) {
        this.selectedCourseDocument.ref.update({
          showMyTopics: this.showMyTopics ? this.showMyTopics : false,
        });
      }
    },
    selectedTopic1(parentTopic) {
      if (this.selectedTopic1 && parentTopic && parentTopic.ref) {
        parentTopic.ref
          .collection("subTopics")
          .orderBy("createdAt", "asc")
          .onSnapshot((docs) => {
            this.topics2 = [];
            var counter2 = 0;
            docs.docs.forEach((doc) => {
              var subTopic = doc.data();
              subTopic.id = doc.id;
              subTopic.ref = doc.ref;

              subTopic.name = this._getCapText(subTopic.courseTopicName);
              subTopic.createdByDisplayName = subTopic.createdByLastName
                ? subTopic.createdByLastName[0] +
                  "." +
                  subTopic.createdByFirstName
                : subTopic.createdByFirstName;

              subTopic.children = null;
              subTopic.topicIndex = 2;
              this.loading = true;

              if (!subTopic.deleted) {
                counter2++;
                subTopic.index = counter2;
                if (!subTopic.numbering) {
                  subTopic.ref.update({ numbering: counter2 });
                }
                this.topics2.push(subTopic);
              }
            });
            this.loading = false;
          });
      }
    },
    // selectedTopics1(val) {
    //   console.log(val, "ffa");
    // },
    // selectedItemToDelete(val) {
    //   console.log(val);
    // },
    selectedTopic2(subTopic) {
      if (this.selectedTopic2 && subTopic && subTopic.ref) {
        subTopic.ref.collection("subSubTopics").onSnapshot((docs) => {
          var counter3 = 0;
          this.topics3 = [];
          // this.selectedTopic2.totalTime = 0;
          docs.docs.forEach((doc) => {
            var subSubTopic = doc.data();
            subSubTopic.id = doc.id;
            subSubTopic.ref = doc.ref;
            subSubTopic.name = subSubTopic.topicName;
            subSubTopic.createdByDisplayName = subSubTopic.createdByLastName
              ? subSubTopic.createdByLastName[0] +
                "." +
                subSubTopic.createdByFirstName
              : subSubTopic.createdByFirstName;
            subSubTopic.topicIndex = 3;
            if (!subSubTopic.deleted) {
              counter3++;
              subSubTopic.index = counter3;
              if (!subSubTopic.numbering) {
                subSubTopic.ref.update({ numbering: counter3 });
              }
              // if (subSubTopic.time && subSubTopic.time > 0)
              //   this.selectedTopic2.totalTime =
              //     this.selectedTopic2.totalTime + subSubTopic.time;
              // else
              //   this.selectedTopic2.totalTime =
              //     this.selectedTopic2.totalTime + 1;
              this.topics3.push(subSubTopic);
            }
          });
          // this.selectedTopic2.ref.update({
          //   totalTime: this.selectedTopic2.totalTime,
          //   time: this.selectedTopic2.totalTime,
          // });
          this.loading = false;
        });
      }
    },
  },
  created() {
    if (!this.zSchool) {
      this.zSchool = this.userData.school;
    }
    if (!this.selectedTeacher) this.selectedTeacher = this.userData;
    this.headerNames = [
      {
        text: "No.",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Төлөв",
        align: "start",
        sortable: true,
        value: "isDatabaseExists",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];

    this.topics1Headers = [
      {
        text: "No.",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Сэдвийн нэр",
        value: "name",
        sortable: true,
      },
      {
        text: "Too",
        value: "length",
        sortable: true,
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];
    this.subTopicHeaders = [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Сэдвийн нэр",
        value: "name",
        sortable: true,
      },
      {
        text: "Too",
        value: "length",
        sortable: true,
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];
    this.zSchool.ref
      .collection("lessons-" + this.zSchool.currentYear)
      .where("teacherRefs", "array-contains", this.selectedTeacher.ref)
      .orderBy("courseInfo.COURSE_NAME", "asc")
      .onSnapshot((docs) => {
        this.lessons = [];
        docs.forEach(async (doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          lesson.lessonGroups = null;
          lesson.calendarData = null;
          lesson.databaseExists = false;
          lesson.curriculum = null;
          this.zSchool.ref
            .collection("_schoolCurriculums-" + this.zSchool.currentYear)
            .doc(lesson.courseInfo.COURSE_ID.toString())
            .onSnapshot((doc) => {
              if (doc.exists) {
                lesson.curriculum = doc.data();
                lesson.curriculum.ref = doc.ref;
                lesson.curriculum.id = doc.id;
                lesson.databaseExists = doc.exists;
              }
            });
          this.lessons.push(lesson);
        });
      });
  },
  methods: {
    _print(lesson) {
      console.log(lesson.ref.path);
    },
    _clearBulks() {
      this.bulkTotalTime = null;
      this.bulkTotalTopicNumber = null;
      this.bulkTopicIndex = null;
      this.bulkTopicList = null;
      this.bulkText = null;
      this.bulkTabled = false;
    },
    _saveBulkTabled() {
      console.log(this.selectedSubSubTopic);
      console.log(this.bulkTopicIndex, this.filteredTopics3.length);
      console.log(
        this.bulkTopicIndex,
        this.bulkTopicList,
        this.selectedTopic1,
        "bac"
      );
      var batch = fb.db.batch();
      if (
        this.bulkTopicIndex == 3 &&
        this.bulkTopicList &&
        this.selectedTopic2
      ) {
        console.log(this.bulkTopicIndex, this.selectedTopic2);
        var docRef = this.selectedTopic2.ref;
        var collectionName = "subSubTopics";
        var lastIndex = this.filteredTopics3 ? this.filteredTopics3.length : 0;

        var counter = 0;
        for (const xx of this.bulkTopicList) {
          if (xx.topic) {
            counter++;
            var topicData = {};
            topicData.topicName = xx.topic;
            topicData.homework = xx.homework ? xx.homework : null;
            topicData.description = xx.notes ? xx.notes : null;
            topicData.conclusion = xx.conclusion ? xx.conclusion : null;
            topicData.time = xx.time ? Number(xx.time) : 1;
            topicData.createdAt = new Date();
            topicData.createdByRef = this.selectedTeacher.ref;
            topicData.createdByFirstName = this.selectedTeacher.firstName;
            topicData.createdByLastName = this.selectedTeacher.lastName;
            topicData.numbering = lastIndex + counter;
            console.log(xx.topic, topicData.numbering, lastIndex, counter);
            batch.set(docRef.collection(collectionName).doc(), topicData, {
              merge: true,
            });
          }
        } //for
        batch.commit().then(() => {
          // this.bulkText = null;
          // this.bulkTopicIndex = null;
          this._clearBulks();
          this.showTopic3DialogBulk = !this.showTopic3DialogBulk;
        });
      } else if (
        this.bulkTopicIndex == 2 &&
        this.bulkTopicList &&
        this.selectedTopic1
      ) {
        var docRef1 = this.selectedTopic1.ref;
        var collectionName1 = "subTopics";
        var lastIndex1 = this.filteredTopics2 ? this.filteredTopics2.length : 0;
        var counter2 = 0;
        for (const xx of this.bulkTopicList) {
          if (xx.topic) {
            counter2++;
            var topicData2 = {};
            topicData2.topicName = xx.topic;
            topicData2.homework = xx.homework ? xx.homework : null;
            topicData2.notes = xx.notes ? xx.notes : null;
            topicData2.conclusion = xx.conclusion ? xx.conclusion : null;
            topicData2.time = xx.time ? Number(xx.time) : 1;
            topicData2.createdAt = new Date();
            topicData2.createdByRef = this.selectedTeacher.ref;
            topicData2.createdByFirstName = this.selectedTeacher.firstName;
            topicData2.createdByLastName = this.selectedTeacher.lastName;
            topicData2.numbering = lastIndex1 + counter2;
            batch.set(docRef1.collection(collectionName1).doc(), topicData2, {
              merge: true,
            });
          }
        } //for
        batch.commit().then(() => {
          // this.bulkText = null;
          // this.bulkTopicIndex = null;
          this._clearBulks();
          this.showTopic3DialogBulk = !this.showTopic3DialogBulk;
        });
      }
    },
    _bulkAdd(topicIndex) {
      this.bulkText = null;
      this.bulkTopicIndex = topicIndex;
      this.showTopic3DialogBulk = !this.showTopic3DialogBulk;
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _saveTopic3Bulk() {
      console.log(this.selectedTopic2, this.filteredTopics3);
      var docRef = null;
      var collectionName = null;
      var topicData = null;
      var lastIndex = null;
      var arrayOfLines = null;
      var counter = 0;
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        arrayOfLines = this.bulkText.trim().match(/[^\r\n]+/g);
      }
      if (this.selectedTopic1 && this.bulkTopicIndex == 2) {
        docRef = this.selectedTopic1.ref;
        collectionName = "subTopics";
        lastIndex = this.filteredTopics2 ? this.filteredTopics2.length : 0;

        if (docRef && collectionName) {
          if (arrayOfLines != null && arrayOfLines.length > 0) {
            for (var value2 of arrayOfLines) {
              counter++;
              topicData = { courseTopicName: value2 };
              topicData.createdAt = new Date();
              topicData.createdByRef = this.selectedTeacher.ref;
              topicData.createdByFirstName = this.selectedTeacher.firstName;
              topicData.createdByLastName = this.selectedTeacher.lastName;
              topicData.numbering = lastIndex + counter;
              batch.set(docRef.collection(collectionName).doc(), topicData, {
                merge: true,
              });
            }
            batch.commit().then(() => {
              this.bulkText = null;
              this.bulkTopicIndex = null;
              this.showTopic3DialogBulk = !this.showTopic3DialogBulk;
            });
          }
        } //if
      } else if (this.selectedTopic2 && this.bulkTopicIndex == 3) {
        docRef = this.selectedTopic2.ref;
        collectionName = "subSubTopics";
        lastIndex = this.filteredTopics3 ? this.filteredTopics3.length : 0;
        if (docRef && collectionName) {
          if (arrayOfLines != null && arrayOfLines.length > 0) {
            for (var value3 of arrayOfLines) {
              counter++;
              topicData = { topicName: value3 };
              topicData.createdAt = new Date();
              topicData.createdByRef = this.selectedTeacher.ref;
              topicData.createdByFirstName = this.selectedTeacher.firstName;
              topicData.createdByLastName = this.selectedTeacher.lastName;
              topicData.numbering = lastIndex + counter;
              batch.set(docRef.collection(collectionName).doc(), topicData, {
                merge: true,
              });
            }
            batch.commit().then(() => {
              this.bulkText = null;
              this.bulkTopicIndex = null;
              this.showTopic3DialogBulk = !this.showTopic3DialogBulk;
            });
          }
        } //if
      }
    },
    closeVideo() {
      this.showVideoDialog = false;
      const iframe = this.$refs.playerFrame;
      const player = iframe.contentWindow;
      if (player && player.postMessage) {
        player.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          "*"
        );
      }
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      } else {
        return "https://www.loom.com/embed/459052232f0c4df69be88d71515571d4";
      }
    },
    _moveUp(item, list, index) {
      if (item.numbering > 1) {
        var xx = list[index - 1];
        if (xx) {
          xx.ref.update({ numbering: fb.firestore.FieldValue.increment(1) });
          item.ref.update({ numbering: fb.firestore.FieldValue.increment(-1) });
        }
      }
    },
    _moveDown(item, list, index) {
      // console.log(item, item.numbering,list, index);
      if (list && index < list.length) {
        var xx = list[index + 1];
        if (xx) {
          xx.ref.update({ numbering: fb.firestore.FieldValue.increment(-1) });
          item.ref
            .update({ numbering: fb.firestore.FieldValue.increment(1) })
            .then(() => {
              console.log("aaa");
            });
        }
      }
    },
    _copyTopic1(item) {
      this.selectedTopic1 = item;
      this.selectedTopic1.newName = this.selectedTopic1.parentCourseTopicName;
      this.moveSelectedTopicDialog = !this.moveSelectedTopicDialog;
    },
    async _confirmMoveTopic1() {
      this.moving = true;
      var tmp = {};
      tmp.createdByRef = this.selectedTeacher.ref;
      tmp.createdByFirstName = this.selectedTeacher.firstName;
      tmp.createdByLastName = this.selectedTeacher.lastName;
      tmp.createdByDisplayName = tmp.createdByLastName
        ? tmp.createdByLastName[0] + "." + tmp.createdByFirstName
        : tmp.createdByFirstName;
      tmp.children = [];
      tmp.createdAt = new Date();
      tmp.createdByESIS = false;
      tmp.name = this.selectedTopic1NewName
        ? this.selectedTopic1NewName
        : "NewTopic";
      tmp.parentCourseTopicName = tmp.name;
      tmp.parentCourseTopicId = tmp.name + "-" + new Date().toString();
      console.log(tmp);
      //parentCourseTopicId
      //parentCourseTopicName

      //courseTopicId, courseTopicName, topicName topicIndex
      // createdAt
      // createdByDisplayName
      // createdByFirstName
      // createdByLastName
      // createdByRef

      var newTopic1 = await this.zSchool.ref
        .collection("_schoolCurriculums-" + this.zSchool.currentYear)
        .doc(this.selectedCourse.courseInfo.COURSE_ID.toString())
        .collection("esisTopics")
        .add(tmp, { merge: true });

      var counter = 0;
      for (const parentTopic of this.selectedTopics1) {
        console.log(parentTopic.children);
        var tmp2 = {};
        tmp2.courseTopicName = parentTopic.parentCourseTopicName;
        tmp2.courseTopicId = parentTopic.parentCourseTopicId;
        tmp2.createdByESIS = parentTopic.createdByESIS;
        tmp2.createdAt = new Date();
        tmp2.parentCourseTopicName = tmp.parentCourseTopicName;
        tmp2.parentCourseTopicId = newTopic1.id;
        tmp2.createdByRef = this.selectedTeacher.ref;
        tmp2.createdByFirstName = this.selectedTeacher.firstName;
        tmp2.createdByLastName = this.selectedTeacher.lastName;
        tmp2.createdByDisplayName = tmp.createdByLastName
          ? tmp.createdByLastName[0] + "." + tmp.createdByFirstName
          : tmp.createdByFirstName;

        this.movingItem = tmp2.courseTopicName;
        const newTopic2 = await newTopic1.collection("subTopics").add(tmp2);
        counter++;
        console.log(counter, newTopic2.path);

        parentTopic.ref
          .collection("subTopics")
          .orderBy("createdAt", "asc")
          .get()
          .then((docs) => {
            docs.docs.forEach(async (doc) => {
              var subTopic = doc.data();
              subTopic.id = doc.id;
              subTopic.ref = doc.ref;

              var tmp3 = {};
              tmp3.createdAt = new Date();
              tmp3.createdByRef = this.selectedTeacher.ref;
              tmp3.createdByFirstName = this.selectedTeacher.firstName;
              tmp3.createdByLastName = this.selectedTeacher.lastName;
              tmp3.createdByDisplayName = tmp.createdByLastName
                ? tmp.createdByLastName[0] + "." + tmp.createdByFirstName
                : tmp.createdByFirstName;
              tmp3.topicName = subTopic.courseTopicName;
              this.movingItem = tmp3.topicName;
              var xx = await newTopic2.collection("subSubTopics").add(tmp3);
              console.log(xx.path);
            });
          });
      }
      //cloase dialog
      this.moving = false;
      this.movingItem = null;
      this.isMovingTopics1 = !this.isMovingTopics1;
      this.moveSelectedTopicDialog = !this.moveSelectedTopicDialog;
    },
    _saveTopic3() {
      console.log(this.selectedSubSubTopic);
      if (
        this.selectedSubSubTopic.topicName &&
        this.selectedSubSubTopic.topicName.length > 0 &&
        this.selectedSubSubTopic.time > 0
      ) {
        if (this.selectedTopic2 && this.selectedSubSubTopic.ref) {
          console.log(this.selectedSubSubTopic);
          this.selectedSubSubTopic.ref
            .update(this.selectedSubSubTopic)
            .then(() => {
              this.selectedSubSubTopic.ref.parent.parent
                .update({
                  createdByRef: this.selectedTeacher.ref,
                  createdByFirstName: this.selectedTeacher.firstName,
                  createdByLastName: this.selectedTeacher.lastName,
                  createdByDisplayName: this.userData.DISPLAY_NAME,
                })
                .then(() => {
                  this.selectedSubSubTopic = null;
                  this.showTopic3Dialog = !this.showTopic3Dialog;
                });
            });
        } else if (this.selectedTopic2) {
          var lastIndex = this.topics3 ? this.topics3.length : 0;
          this.selectedSubSubTopic.numbering = lastIndex + 1;

          this.selectedSubSubTopic.createdAt = new Date();
          this.selectedSubSubTopic.createdByRef = this.selectedTeacher.ref;
          this.selectedSubSubTopic.createdByTeacherRef =
            this.selectedTeacher.ref;

          this.selectedSubSubTopic.createdByFirstName =
            this.selectedTeacher.firstName;
          this.selectedSubSubTopic.createdByLastName =
            this.selectedTeacher.lastName;

          var docRef = this.selectedTopic2.ref.collection("subSubTopics").doc();

          docRef.set(this.selectedSubSubTopic, { merge: true }).then(() => {
            docRef.parent.parent
              .update({
                createdByRef: this.selectedTeacher.ref,
                createdByFirstName: this.selectedTeacher.firstName,
                createdByLastName: this.selectedTeacher.lastName,
                createdByDisplayName: this.userData.DISPLAY_NAME,
              })
              .then(() => {
                this.showTopic3Dialog = !this.showTopic3Dialog;
              });
          });
        }
      } else {
        this.$swal.fire(
          "Сэдвийн нэр, ороx цагийг заавал оруулна. Цаашилвал даалгавар, үа, дүгнэлтээ оруулвал XИЧЭЭЛ дээр нэмэxэд бүгд нэмэгдэнэ."
        );
      }
    },
    _saveTopic2() {
      var tmp = Object.assign({}, this.selectedTopic2);
      tmp.createdByRef = this.selectedTeacher.ref;
      tmp.createdByFirstName = this.selectedTeacher.firstName;
      tmp.createdByLastName = this.selectedTeacher.lastName;
      tmp.createdByDisplayName = tmp.createdByLastName
        ? tmp.createdByLastName[0] + "." + tmp.createdByFirstName
        : tmp.createdByFirstName;
      tmp.children = null;
      tmp.createdAt = new Date();
      if (tmp && tmp.ref) {
        tmp.ref.update(tmp).then(() => {
          tmp.ref.parent.parent
            .update({
              createdByRef: this.selectedTeacher.ref,
              createdByFirstName: this.selectedTeacher.firstName,
              createdByLastName: this.selectedTeacher.lastName,
              createdByDisplayName: this.userData.DISPLAY_NAME,
            })
            .then(() => {
              this.showTopic2Dialog = !this.showTopic2Dialog;
            });
        });
      } else {
        tmp.createdAt = new Date();
        var docRef = this.selectedTopic1.ref.collection("subTopics").doc();
        docRef.set(tmp, { merge: true }).then(() => {
          docRef.parent.parent
            .update({
              createdByRef: this.selectedTeacher.ref,
              createdByFirstName: this.selectedTeacher.firstName,
              createdByLastName: this.selectedTeacher.lastName,
              createdByDisplayName: this.userData.DISPLAY_NAME,
            })
            .then(() => {
              this.showTopic2Dialog = !this.showTopic2Dialog;
            });
        });
      }
    },
    _saveTopic1() {
      console.log(this.selectedTopic1);
      var tmp = Object.assign({}, this.selectedTopic1);
      tmp.createdByRef = this.selectedTeacher.ref;
      tmp.createdByFirstName = this.selectedTeacher.firstName;
      tmp.createdByLastName = this.selectedTeacher.lastName;
      tmp.createdByDisplayName = tmp.createdByLastName
        ? tmp.createdByLastName[0] + "." + tmp.createdByFirstName
        : tmp.createdByFirstName;
      tmp.children = null;
      tmp.createdAt = new Date();

      if (tmp && tmp.ref) {
        tmp.ref.update(tmp).then(() => {
          this.showTopic1Dialog = !this.showTopic1Dialog;
        });
      } else {
        tmp.createdAt = new Date();
        this.zSchool.ref
          .collection("_schoolCurriculums-" + this.zSchool.currentYear)
          .doc(this.selectedCourse.courseInfo.COURSE_ID.toString())
          .collection("esisTopics")
          .doc()
          .set(tmp, { merge: true })
          .then(() => {
            this.showTopic1Dialog = !this.showTopic1Dialog;
          });
      }
    },
    // _deleteTopic1(item) {
    //   item.ref.delete();
    // },
    // _deleteTopic2(item) {
    //   item.ref.delete();
    // },
    _deleteTopic(item, topicNumber) {
      if (topicNumber == 1) this._clickedTopic1(item);
      else if (topicNumber == 2) this._clickedTopic2(item);
      else if (topicNumber == 3) this._clickedTopic3(item);

      if (item.createdByESIS) {
        this.selectedItemToDelete = item;
        this.showDeleteDialog = true;
      } else {
        if (
          (item.createdByRef && item.createdByRef.id == this.userData.ref.id) ||
          (item.createdByTeacherRef &&
            item.createdByTeacherRef.id == this.userData.ref.id)
        ) {
          this.selectedItemToDelete = item;
          this.showDeleteDialog = !this.showDeleteDialog;
          this.selectedTopicIndex = topicNumber;
        } else {
          this.snackbar = true;
          this.showMessage = "Энэ сэвдийг та үүсгээгүй тул устгаx боломжгүй!";
        }
      }
    },
    _deleteConfirm() {
      if (this.selectedItemToDelete) {
        if (
          (this.selectedItemToDelete.createdByRef &&
            this.selectedItemToDelete.createdByRef.id ==
              this.userData.ref.id) ||
          (this.selectedItemToDelete.createdByTeacherRef &&
            this.selectedItemToDelete.createdByTeacherRef.id ==
              this.userData.ref.id)
        ) {
          this.selectedItemToDelete.ref.delete().then(() => {
            // console.log(this.selectedTopicIndex)
            if (this.selectedTopicIndex == 1) {
              this.topics2 = null;
              this.selectedTopic1 = null;
            }
            if (this.selectedTopicIndex == 2) {
              this.topics3 = null;
              this.selectedTopic2 = null;
            }
            this.selectedItemToDelete = null;
            this.showDeleteDialog = !this.showDeleteDialog;
            this.selectedTopicIndex = -1;
          });
        } else {
          var xx = {
            deleted: true,
            deletedByRef: this.selectedTeacher.ref,
            deletedByName: this.selectedTeacher["DISPLAY_NAME"]
              ? this.selectedTeacher["DISPLAY_NAME"]
              : this.selectedTeacher["firstName"],
          };
          this.selectedItemToDelete.ref.update(xx).then(() => {
            if (this.selectedItemToDelete.topicIndex == 1) {
              this.selectedTopic1 = null;
            }
            if (this.selectedItemToDelete.topicIndex == 2) {
              this.selectedTopic2 = null;
            }
            this.selectedItemToDelete = null;
            this.showDeleteDialog = !this.showDeleteDialog;
          });
        }
      }
    },
    _editTopic1(item) {
      this.selectedTopic1 = Object.assign({}, item);
      this.showTopic1Dialog = !this.showTopic1Dialog;
    },
    _editTopic2(item) {
      this.selectedTopic2 = Object.assign({}, item);
      this.showTopic2Dialog = !this.showTopic2Dialog;
    },
    _editTopic3(item) {
      this.selectedSubSubTopic = Object.assign({}, item);
      this.showTopic3Dialog = !this.showTopic3Dialog;
    },

    _clickedTopic3(item) {
      this.selectedTopic3 = item;
      console.log(item.ref.path);
    },
    _clickedTopic2(item) {
      this.selectedTopic2 = item;
      console.log(item.ref.path);
    },
    _clickedTopic1(item) {
      this.selectedTopic2 = null;
      this.selectedTopic1 = item;
      console.log(item.ref.path);
    },
    _readFromFirebase(item) {
      console.log(item);
      this.$swal.fire(
        "Сэдвийн санг улирлын төлөвлөгөөтэй уялдуулан ЗӨВ, ЦЭГЦТЭЙ арга xэмжээ авна. Тиймээс бид сургуультай яриx ажил байгаа тул та түр xүлээнэ үү. Та энэ улиралд зааx сэдвээ сайн бэлдэж байгаарай. Маш гоё болгоно. 9 сарын 16 -наас эxлэнэ."
      );
      // this.selectedCourse = item;
      // this.selectedTopic1 = null;
      // this.selectedTopic2 = null;
      // this.topics2 = null;
      // this.topics3 = null;
      // this._readTopicsFromFirebase(this.selectedCourse);
      // this.showCurriculumDialog = !this.showCurriculumDialog;
    },
    _loadTopicsFromESIS(item) {
      console.log(item.ref.path);
      console.log(item.courseInfo.COURSE_ID.toString());
      this.$swal({
        title: "Xичээлийн сэдвийн санг ESIS системээс татаx уу?",
        text: "ESIS системээс татаxад ESIS систем, болон таны интернэтийн xурдаас xамаарч 1-2 минут орчим xугацаа болоxыг анxаарна уу!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this._readFromESIS(item);
        }
      });
    },
    _readTopicsFromFirebase(lesson) {
      this.loading = true;
      this.zSchool.ref
        .collection("_schoolCurriculums-" + this.zSchool.currentYear)
        .doc(lesson.courseInfo.COURSE_ID.toString())
        .onSnapshot((doc) => {
          this.selectedCourseDocument = doc.data();
          this.selectedCourseDocument.id = doc.id;
          this.selectedCourseDocument.ref = doc.ref;
          this.showMyTopics = this.selectedCourseDocument.showMyTopics;
        });

      this.zSchool.ref
        .collection("_schoolCurriculums-" + this.zSchool.currentYear)
        .doc(lesson.courseInfo.COURSE_ID.toString())
        .collection("esisTopics")
        .orderBy("createdAt", "asc")
        .onSnapshot((docs) => {
          this.lessonTopicsFromFirebase = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            var parentTopic = doc.data();
            parentTopic.id = doc.id;
            parentTopic.ref = doc.ref;
            parentTopic.children = null;
            parentTopic.topicIndex = 1;
            parentTopic.name = parentTopic.parentCourseTopicName;
            parentTopic.createdByDisplayName = parentTopic.createdByLastName
              ? parentTopic.createdByLastName[0] +
                "." +
                parentTopic.createdByFirstName
              : parentTopic.createdByFirstName;

            console.log(parentTopic, parentTopic.parentCourseTopicName);
            this.loading = true;
            if (!parentTopic.deleted) {
              counter++;
              parentTopic.index = counter;
              if (!parentTopic.numbering) {
                parentTopic.ref.update({ numbering: counter });
              }
              parentTopic.name = this._getCapText(
                parentTopic.parentCourseTopicName
              );
              this.lessonTopicsFromFirebase.push(parentTopic);
            }
          });
          this.loading = false;
        });
    },
    _getCapText(inputString) {
      if (!inputString) {
        return inputString;
      }
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    },
    async _readFromESIS(lesson) {
      this.loading = true;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.zSchool._esisUserName,
            password: this.zSchool._esisPword,
          }
        )
        .then(async (res) => {
          var lessonTopics = {};
          this.token = res.data.token;
          // console.log("token read", lesson.courseInfo.COURSE_ID);
          var topics = await esisAPIS.getTOPICS_COURSE(
            this.token,
            lesson.courseInfo.COURSE_ID
          );
          this.loading = false;
          var batch = fb.db.batch();
          var docRef = this.zSchool.ref
            .collection("_schoolCurriculums-" + this.zSchool.currentYear)
            .doc(lesson.courseInfo.COURSE_ID.toString());
          if (topics) {
            batch.set(
              docRef,
              { courseInfo: lesson.courseInfo, createdAt: new Date() },
              { merge: true }
            );
            topics.forEach((topic) => {
              if (topic.parentCourseTopicId) {
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc(topic.parentCourseTopicId.toString()),
                  {
                    parentCourseTopicId: topic.parentCourseTopicId,
                    parentCourseTopicName: topic.parentCourseTopicName,
                    createdAt: new Date(),
                    createdByESIS: true,
                  },
                  { merge: true }
                );
                topic.createdByESIS = true;
                topic.createdAt = new Date();
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc(topic.parentCourseTopicId.toString())
                    .collection("subTopics")
                    .doc(topic.courseTopicId.toString()),
                  topic,
                  { merge: true }
                );
              } else {
                batch.set(
                  docRef.collection("esisTopics").doc("noParent"),
                  {
                    parentCourseTopicId: "noParent",
                    parentCourseTopicName: "noParent",
                    createdAt: new Date(),
                    createdByESIS: true,
                  },
                  { merge: true }
                );
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc("noParent")
                    .collection("subTopics")
                    .doc(topic.courseTopicId.toString()),
                  topic,
                  { merge: true }
                );
              }
            });
          }
          batch.commit().then(() => {
            console.log("done!!");
          });
          console.log(lessonTopics);
        });
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.v-line {
  border-right: 1px solid #ccc;
}

.v-data-table--item.selected {
  background-color: lightblue;
  /* Customize the background color for selected rows */
}
</style>
